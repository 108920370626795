class StatusGestaoFaturamentoPedidoService {
    lista() {
        return [
            { codigo: "A_INICIAR", descricao: "À Iniciar" },
            { codigo: "INICIADO", descricao: "Iniciado" },
            { codigo: "CONCLUÍDO", descricao: "Concluído" }
        ]
    }

    buscarPorCodigo(codigo) {
        return this.lista().find(item => {
            return item.codigo === codigo;
        })
    }
}

export default new StatusGestaoFaturamentoPedidoService();