<template>
    <div class="card">
        <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
            <div class="w-full align-items-center">            
                <h3>
                    <img src="layout/images/order.png" alt="" style="font-size: 1.4rem">
                    Gestão Pedidos
                </h3>
                <hr />
            </div>

            <Fieldset legend="Dados Gerais">
                <div class="grid p-fluid formgrid">
                    <div class="col-12 sm:col-8 md:col-8 lg:col-8 xl:col-8">
                        <div class="field">
                            <label>
                                <strong>Contrato Interno<span style="color:red;">&nbsp;*</span></strong>
                            </label>
                            
                            <ContratoZoom
                                codigo="contratoInternoId"
                                descricao="nome"
                                :selecionado="contrato"
                                :disabled="gestao.id != null"
                                :invalid="campoInvalido.contrato"
                                :paginacao="contratoConsulta"
                                @pesquisar="pesquisarContrato"
                                @selecionado="selecionarContrato"
                            />
                        </div>
                    </div>

                    <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                        <div class="field">
                            <label>
                                <strong>CNPJ</strong>
                            </label>
                            
                            <InputText v-model="gestao.cnpjCliente" :disabled="true"/>
                        </div>
                    </div>

                    <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                        <div class="field">
                            <label>
                                <strong>Status</strong>
                            </label>
                            <InputText :disabled="true" v-model="gestao.descricaoStatus"/>
                        </div>
                    </div>

                    <div class="col-6 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                        <div class="field">
                            <label>
                                <strong>Destino</strong>
                            </label>
                            <InputText :disabled="true" v-model="destino.label"/>
                        </div>
                    </div>

                    <div class="col-6 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                        <div class="field">
                            <label>
                                <strong>Corretora</strong>
                            </label>                            
                            <InputText :disabled="true"  v-model="corretora.label"/>
                        </div>
                    </div>

                    <div class="col-6 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                        <div class="field">
                            <label>
                                <strong>Nº Contrato Corretora</strong>
                            </label>                            
                            <InputText :disabled="true" v-model="gestao.contratoCorretora"/>
                        </div>
                    </div>

                  <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                    <div class="field">
                      <label>
                        <strong>Modalidade Precificação</strong>
                      </label>

                      <InputText :disabled="true" v-model="gestao.modalidadePrecificacao"/>
                    </div>
                  </div>

                    <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                        <div class="field">
                            <label>
                                <strong>Modalidade Frete</strong>
                            </label>
                            
                            <InputText :disabled="true" v-model="gestao.modalidadeFrete"/>
                        </div>
                    </div>

                    <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                        <div class="field">
                            <label>
                                <strong>Frete Orçado ( R$ / TON)</strong>
                            </label>
                            <InputNumber class="w-full" disabled v-model="gestao.freteOrcado" mode="decimal" locale="pt-BR" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="999999999" />
                        </div>
                    </div>

                    <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                        <div class="field">
                            <label for="inicio-embarque">
                                <strong>Início Embarque</strong>
                            </label>
                            
                            <Calendar
                                disabled
                                v-model="gestao.inicioEmbarque"
                                id="inicio-embarque"
                                dateFormat="dd/mm/yy"
                            />
                        </div>
                    </div>

                    <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                        <div class="field">
                            <label for="fim-embarque">
                                <strong>Fim Embarque</strong>
                            </label>
                            
                            <Calendar
                                disabled
                                v-model="gestao.fimEmbarque"
                                id="fim-embarque"
                                dateFormat="dd/mm/yy"
                                :minDate="gestao.inicioEmbarque"
                            />
                        </div>
                    </div>

                    <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                        <div class="field">
                            <label>
                                <strong>Produto</strong>
                            </label>
                            
                            <InputText :disabled="true" v-model="gestao.descProduto"/>
                        </div>
                    </div>

                    <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                        <div class="field">
                            <label>
                                <strong>Total Contrato</strong>
                            </label>
                            <div class="p-inputgroup">
                                <InputNumber v-model="gestao.saldoContrato" locale="pt-BR" class="valor-campo-numerico w-full" :minFractionDigits="2" :maxFractionDigits="2" disabled/>
                                <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="gestao.siglaUnidadeMedida"/>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                        <div class="field">
                            <label>
                                <strong>Pedido à Gerar</strong>
                            </label>
                            <div class="p-inputgroup">
                                <InputNumber v-model="gestao.saldoPedido" locale="pt-BR" class="valor-campo-numerico w-full" :minFractionDigits="2" :maxFractionDigits="2" disabled/>
                                <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="gestao.siglaUnidadeMedida"/>
                            </div>                            
                        </div>
                    </div>

                    <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                        <div class="field">
                            <label>
                                <strong>Saldo à Embarcar</strong>
                            </label>
                            <div class="p-inputgroup">
                                <InputNumber v-model="gestao.saldoFaturamento" locale="pt-BR" class="valor-campo-numerico w-full" :minFractionDigits="2" :maxFractionDigits="2" disabled/>
                                <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="gestao.siglaUnidadeMedida"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Fieldset>

            <TabView v-model:activeIndex="tabViewActiveIndex" class="mt-3" @click="refreshPedido()">

                <TabPanel>
                    <template #header>
                        <i class="pi pi-file mr-2" style="font-size: 1rem;"></i>
                        <span>Pedido</span>
                    </template>
                    <PedidoForm 
                        v-if="gestao.id != null" 
                        :gestao="gestao"
                        :tela="'pedido'"
                        :refreshPedidoCount="refreshPedidoCount" 
                        @atualizarGestao="refreshFaturamento"/>
                </TabPanel>  

                <TabPanel>
                    <template #header>
                        <i class="pi pi-file mr-2" style="font-size: 1rem;"></i>
                        <span>Faturamento</span>
                    </template>
                    <FaturamentoForm 
                        v-if="gestao.id != null" 
                        :refreshFaturamentoCount="refreshFaturamentoCount" 
                        :gestao="gestao" @atualizarGestao="refreshPedido" 
                        :siglaUnidadeMedida="gestao.siglaUnidadeMedida"/>
                </TabPanel>

            </TabView>  

            <div class="flex mt-3">
                <Button
                    icon="pi pi-replay"
                    label="Voltar"
                    class="p-button-text ml-2"
                    @click="voltar"
                ></Button>
            </div>
        </div>
    </div>
</template>

<script>
import StatusGestaoFaturamentoPedidoService from '../../../service/pedido/StatusGestaoFaturamentoPedidoService';
import GestaoFaturamentoPedidoService from '../../../service/pedido/GestaoFaturamentoPedidoService';
import ContratoCompraVendaService from '../../../service/ContratoCompraVendaService';
import Formatacao from '../../../utilities/Formatacao';
import ContratoZoom from '../../zoom/ContratoZoom.vue';
import UnidadeMedidaService from '../contrato/service/UnidadeMedidaService.js';
import FaturamentoForm from './FaturamentoForm.vue';
import PedidoForm from './PedidoForm.vue';

export default {
    name: "GestaoFaturamentoPedidoForm",
    components: { ContratoZoom, PedidoForm, FaturamentoForm },
    props: {
        id: {
            type: Number,
            required: false
        }
    },
    data() {
        return {
            campoInvalido: {},
            corretora: {},
            destino:{},
            gestao: {},
            contrato: {},
            contratoConsulta: {},
            pedidos: [],
            tabViewActiveIndex: 0,
            activeIndexAnterior: 0,
            refreshPedidoCount: 0,
            refreshFaturamentoCount: 0,
            status: StatusGestaoFaturamentoPedidoService.lista(),
            isAdministracao: false,
            isLogistica: false,
            isOperacao: false,
            isComercial: false

        }
    },
    created() {
      this.isAdministracao = this.$store.state.auth.user.isAdministracao;
      this.isLogistica = this.$store.state.auth.user.isLogistica;
      this.isOperacao = this.$store.state.auth.user.isOperacao;
      this.isComercial = this.$store.state.auth.user.isComercial;

    },
  methods: {
        voltar() {
            this.$router.push(`/pedidos`);
        },

        refreshPedido:function() {
            if(this.tabViewActiveIndex == 0 && this.activeIndexAnterior == 1){
                this.localizarGestaoFaturamentoPedido();
                this.refreshPedidoCount++;
            }
            this.activeIndexAnterior = this.tabViewActiveIndex;
        },

        refreshFaturamento:function() {
            this.localizarGestaoFaturamentoPedido();
            this.refreshFaturamentoCount++;
        },

        localizarGestaoFaturamentoPedido(){
            if(!!this.id) {
                GestaoFaturamentoPedidoService.buscarPorId(this.id)
                      .then(({ data }) => {
                      let dataGestao = data;
                      ContratoCompraVendaService.buscarPorId(dataGestao.contratoCompraVendaId)
                          .then(({ data }) => {
                              this.preencherForm(dataGestao,data);
                          });
                    })
                    .catch(error => {
                        this.gestao = {};
                        this.contrato = {};

                        const toast = {
                            severity: 'error',
                            summary: `Falha ao buscar a Gestão de Faturamento de Pedidos.`,
                            detail: "Ocorreu uma falha não mapeada, tente novamente e caso o problema persista, favor entra em contato com o Administrador!", 
                            life: 7500
                        }

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message, exception } = error.response.data;

                            if(!!exception) {
                                toast["detail"] = exception;
                            }
                        }

                        this.$toast.add(toast);
                    });
            }
        },

        getDescricaoStatus(codigo){
            if(codigo){
                const statusGestaoFaturamentoPedido = StatusGestaoFaturamentoPedidoService.buscarPorCodigo(codigo);

                if(statusGestaoFaturamentoPedido){
                    return statusGestaoFaturamentoPedido.descricao;
                }else{
                    return "";
                }
            }
        },

        carregarSiglaUnidadeMedida(){
            if(this.gestao.unidade){
                const unidade = UnidadeMedidaService.buscarPorCodigo(this.gestao.unidade);

                if(unidade){
                    this.gestao["siglaUnidadeMedida"] = unidade.sigla;
                }else{
                    this.gestao["siglaUnidadeMedida"] = "";
                }
            }
        },
        
        pesquisarContrato(filtros) {
            filtros.semPedido = true;
            ContratoCompraVendaService.buscarComPaginacao(filtros)
                .then(({ data }) => {
                    if(data) {
                        this.contratoConsulta = data;
                    }
                })
                .catch(error => {
                    this.contratoConsulta = {}

                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao carregar os contratos.',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        selecionarContrato(codigo) {
            if(codigo == undefined || codigo == null) {
                this.contrato = {};
                return;
            }

            ContratoCompraVendaService.buscarPorContratoInterno(codigo)
                .then(({ data }) => {
                    this.gestao["status"] = "A_INICIAR";
                    this.gestao["descricaoStatus"] = this.getDescricaoStatus("A_INICIAR");
                    this.gestao["cnpjCliente"] = data.comprador.cnpj;
                    this.gestao["contratoCompraVendaId"] = data.id;
                    this.gestao["clienteId"] = data.comprador.id;
                    this.gestao["nomeCliente"] = data.comprador.nome;
                    this.gestao["inicioEmbarque"] = null;
                    this.gestao["nomeCliente"] = null;
                    this.gestao["produtoId"] = data.produtoId;
                    this.gestao["codigoProduto"] = data.produtoCodItem;
                    this.gestao["descProduto"] = data.produtoDescItem;
                    this.gestao["modalidadeFrete"] = data.modalidadeFrete;
                    this.gestao["unidade"] = data.unidade;
                    this.gestao["saldoContrato"] = data.quantidade;
                    this.gestao["saldoPedido"] = data.quantidade;
                    this.gestao["saldoFaturamento"] = data.quantidade;
                    this.gestao["versao"] = 0;
                    this.gestao["freteOrcado"] = null;
                    this.gestao["pedidos"] = [];

                    this.contrato["id"] = data.id;
                    this.contrato["nome"] = data.comprador.nome;
                    this.contrato["contratoInternoId"] = data.contratoInternoId;

                    this.carregarSiglaUnidadeMedida();

                })
                .catch(error => {
                    this.gestao.contrato = {};

                    const toast = {
                        severity: 'error',
                        summary: `Falha ao buscar contrato.`,
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;

                        if(!!message) {
                            toast["detail"] = message;
                        }
                    }

                    this.$toast.add(toast);
                });
        },

        preencherForm(data,data2) {
            this.gestao = data;
            this.gestao["inicioEmbarque"] = Formatacao.convertStringToDate(this.gestao["inicioEmbarque"], 'YYYY-MM-DD');
            this.gestao["fimEmbarque"] = Formatacao.convertStringToDate(this.gestao["fimEmbarque"], 'YYYY-MM-DD');
            this.gestao["descricaoStatus"] = this.getDescricaoStatus(data.status);
            if(data2.modalidadePrecificacao=="A_FIXAR"){
              this.gestao["modalidadePrecificacao"] = "A FIXAR - " + data2.modalidadePrecoAFixar.tipoFixacao;
            } else if (data2.modalidadePrecificacao=="FIXO_DOLAR") {
              this.gestao["modalidadePrecificacao"] = "FIXO EM DÓLAR";
            } else {
              this.gestao["modalidadePrecificacao"] = "FIXO";
            }
            if(data.destino) {
                this.destino = data.destino;
                this.destino["label"] = `${ data.destino["nome"] } - ${ data.destino["uf"] }`;
                this.gestao.destino = this.destino;
            }

            if(data.corretora) {
                this.corretora = data.corretora;
                this.corretora["label"] = `${ data.corretora["codigoCliente"] } - ${ data.corretora["nome"] }`;
                this.gestao.corretora = this.corretora;
            }
            
            this.contrato = {
                id: data.contratoCompraVendaId,
                contratoInternoId: data.contratoInternoId,
                nome: data.nomeCliente
            }
            this.carregarSiglaUnidadeMedida();
        },

        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        formatDateCustom(data, format){
            return Formatacao.formatDateCustom(data, format);
        }
    },

    mounted(){
        this.localizarGestaoFaturamentoPedido();
    }
}
</script>

<style scoped>

    .valor-campo-numerico{
        text-align: right; 
        font-weight: bold;
    }
    .sigla-campo-numerico{
        text-align: left; 
        width: 70px !important; 
        font-weight: 600;
    }
</style>