<template>
    <div class="card">
        <div class="flex align-items-center">
            <img src="layout/images/order.png" alt="" style="font-size: 1.4rem">
           <h3 style="margin:0px 5px;">Pedidos</h3>
        </div>

        <hr />

        <DataTable 
            responsiveLayout="stack"
            :value="data"
            v-model:selection="selecionado"
            selectionMode="single"
            showGridlines
            @sort="sort($event)">

            <template #header>
                  <div class="grid p-fluid formgrid align-items-center">
                    <div class="col-12 lg:col-1">
                      <div class="field">
                        <label for="nr-ci">
                          <strong>Pedido</strong>
                        </label>
                        <InputNumber id="nr-pedido" class="w-full" v-model="filter.idPedido" placeholder="Nº pedido"/>
                      </div>
                    </div>

                    <div class="col-12 lg:col-1">
                      <div class="field">
                        <label for="nr-ci">
                          <strong>Contrato Interno</strong>
                        </label>
                        <InputNumber id="nr-ci" class="w-full" v-model="filter.idContrato" placeholder="Nº contrato"/>
                      </div>
                    </div>

                    <div class="col-12 lg:col-3">
                      <div class="field">
                        <label>
                          <strong>Cliente</strong>
                        </label>
                        <ProdutorFiltro
                            tituloModal="Buscar Comprador"
                            :disabled="false"
                            :campoObrigatorio="true"
                            :selecionado="filter.compradorSelecionado"
                            :paginacao="compradorConsulta"
                            @pesquisar="pesquisarComprador"
                            @selecionado="selecionarComprador"
                        />
                      </div>
                    </div>

                    <div class="col-12 lg:col-2">
                      <div class="field">
                        <label>
                          <strong>Período Pedido</strong>
                        </label>

                        <div class="flex align-items-center">
                          <div class="overflow-hidden">
                            <Calendar
                                class="w-full"
                                v-model="periodoInicioPedido"
                                autocomplete="off"
                                dateFormat="dd/mm/y"
                                :maxDate="periodoFimPedido"
                            />
                          </div>

                          <strong class="ml-2 mr-2">à</strong>

                          <div class="overflow-hidden" >
                            <div class="p-inputgroup">
                              <Calendar
                                  class="w-full"
                                  v-model="periodoFimPedido"
                                  autocomplete="off"
                                  dateFormat="dd/mm/y"
                                  :minDate="periodoInicioPedido"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 lg:col-5">
                        <div class="p-inputgroup">
                            <Button
                                label="Filtrar"
                                icon="pi pi-filter"
                                class="p-button-outlined p-button-info"
                                style="margin-top:0.5rem"
                                @click="carregarDados()"
                            ></Button>

                            <Button 
                                title="Limpar Filtro"
                                icon="pi pi-filter-slash"
                                class="p-button-outlined p-button-info"
                                style="margin-left: 1px; margin-top: 0.5rem"
                                @click="limparFiltro()"
                            ></Button>
                          <Button
                              v-if="filter.situacao == 'ATIVO'"
                              type="button"
                              icon="pi pi-ban"
                              label="Listar inativos"
                              class="p-button-outlined p-button-danger mr-2 mt-2"
                              @click="filter.situacao = 'INATIVO'; carregarDados()"
                          ></Button>

                          <Button
                              v-if="filter.situacao == 'INATIVO'"
                              type="button"
                              icon="pi pi-check-circle"
                              label="Listar ativos"
                              class="p-button-outlined mr-2 mt-2"
                              @click="filter.situacao = 'ATIVO'; carregarDados()"
                          ></Button>

                          <Button
                              icon="pi pi-filter"
                              label="Filtro Avançado"
                              class="p-button-outlined"
                              style="min-width:150px; margin-top:0.5rem"
                              @click="contratoFiltroAvancadoDialog = true"
                          ></Button>

                        </div>
                    </div>
                </div>
            </template>

            <template #empty>
                Nenhum registro encontrado.
            </template>

            <template #loading>
                Carregando... Por favor, aguarde.
            </template>

            <Column field="id" header="Pedido" style="width:2rem;"/>

            <Column field="contratoInternoId" header="CI" style="width:2rem;"/>

            <Column field="modalidadeFrete" header="Frete" style="width:2rem;"/>

            <Column header="Destino">
                <template #body="{ data }">
                    {{ data.destino.nome }} / {{ data.destino.uf }}
                </template>
            </Column>

            <Column field="nomeCliente" header="Cliente">
                <template #body="{ data }">
                   <span :title="data.cnpjCliente"> {{ data.nomeCliente }} </span> 
                </template>
            </Column>

            <Column header="Inicio Embarque" style="width:9rem;">
                <template #body="{ data }"> 
                    {{ formatDateCustom(data.inicioEmbarque, 'DD/MM/YYYY') }}
                </template>
            </Column>

            <Column header="Fim Embarque" style="width:9rem;">
                <template #body="{ data }">
                    {{ formatDateCustom(data.fimEmbarque, 'DD/MM/YYYY') }}
                </template>
            </Column>
            
            <Column field="descProduto" header="Produto"/>

            <Column field="saldoContrato" header="Total Contrato" style="width:10rem;text-align: right;">
                <template #body="{ data }">
                    {{ toKg(data.saldoContrato,data.unidade) }} Kg
                </template>
            </Column>

            <Column field="saldoPedido" header="Pedido à Gerar" style="width:10rem;text-align: right;">
                <template #body="{ data }">
                    {{ toKg(data.saldoPedido,data.unidade) }} Kg
                </template>
            </Column>

            <Column field="saldoFaturamento" header="Saldo à Embarcar" style="width:10rem;text-align: right;">
                <template #body="{ data }">
                    {{ toKg(data.saldoFaturamento,data.unidade) }} Kg
                </template>
            </Column>

            <Column header="Ações" style="width:10rem;" class="centralizar-titulo-tabela">
                <template #body="{ data }">
                    <div class="flex-none">

                        <Button 
                            title="Visualizar histórico"
                            icon="pi pi-list"
                            class="p-button-rounded p-button-warning flex-none mr-2"
                            @click="visualizarHistorico(data)"
                        ></Button>

                        <Button
                            v-if="data.ativo"
                            title="Editar"
                            icon="pi pi-pencil"
                            class="p-button-rounded p-button-info flex-none mr-2"
                            @click="editar(data.id)"
                        ></Button>

                        <Button 
                            v-if="data.ativo"
                            title="Inativar"
                            class="p-button-rounded flex-none p-button-danger"
                            icon="pi pi-ban"
                            @click="confirmarAtivarOuInativar(data)"
                        ></Button>

                        <Button 
                            v-if="!data.ativo"
                            title="Ativar"
                            class="p-button-rounded flex-none p-button-info"
                            icon="pi pi-check-circle"
                            @click="confirmarAtivarOuInativar(data)"
                        ></Button>
                    </div>
                </template>
            </Column>
        </DataTable>

        <Paginator
            v-model:first="primeiraLinha"
            :rows="qtdRegistroPorPagina"
            :total-records="totalRegistro"
            :rowsPerPageOptions="[5, 10, 20, 30]"
            @page="onPage" />

    </div>

    <Dialog v-model:visible="ativarOuInativarDialog" :style="{width: '300px'}" header="Alerta" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
            <span>Tem certeza que deseja <strong>{{ selecionado.ativo ? 'inativar' : 'ativar' }}</strong> o embarque do CI: <strong>{{ selecionado.contratoInternoId }}</strong>?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="ativarOuInativarDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="ativarOuInativar()" />
        </template>
    </Dialog>

    <ContratoFiltroAvancado
        :visivel="contratoFiltroAvancadoDialog"
        :filter="filter"
        @filtrar="filtrar">
    </ContratoFiltroAvancado>

    <ModalHistoricoGenerico
        codigoPagina="GESTAO_PEDIDO_FATURAMENTO"
        :id="selecionado.id"
        :descricao="descricaoHistoricoDialog"
        :visivel="historicoDialog"
        @fechar="ocultarHistorico()"
    />

</template>

<script>

import StatusGestaoFaturamentoPedidoService from '../../../service/pedido/StatusGestaoFaturamentoPedidoService';
import GestaoFaturamentoPedidoService from '../../../service/pedido/GestaoFaturamentoPedidoService';
import StorageService from '../../../service/StorageService';
import Formatacao from '../../../utilities/Formatacao';
import ModalHistoricoGenerico from '../../ModalHistoricoGenerico.vue';
import UnidadeMedidaService from '../contrato/service/UnidadeMedidaService.js';
import CorretoraZoom from "@/components/zoom/CorretoraZoom.vue";
import ContratoCompraVendaService from "@/service/ContratoCompraVendaService";
import ContratoFiltroAvancado from "@/components/zoom/ContratoFiltroAvancado.vue";
import contratoFiltroAvancado from "@/components/zoom/ContratoFiltroAvancado.vue";
import ProdutorFiltro from "@/components/cadastro/ProdutorFiltro.vue";
import ProdutorService from "@/service/ProdutorService";

export default {
    name: 'PedidoList',
  computed: {
    contratoFiltroAvancado() {
      return contratoFiltroAvancado
    }
  },
    components: {ProdutorFiltro, ContratoFiltroAvancado, CorretoraZoom, ModalHistoricoGenerico },
    data() {
        return {
            pagina: 0,
            primeiraLinha: 0,
            totalRegistro: 0,
            qtdRegistroPorPagina: 10,  

            data: [],
            municipio: [],

            filter: {},
            ordenar: 'id,desc',
            campoInvalido: {},
            contratoConsulta: {},
            compradorSelecionado: {},
            compradorConsulta: {},

            periodoInicioPedido: null,
            periodoFimPedido: null,
            
            selecionado: {},
            historicoDialog: false,
            ativarOuInativarDialog: false,
            descricaoHistoricoDialog: null,
            contratoFiltroAvancadoDialog: false,
            reload: false,
            
            storange: StorageService.getControlePagina("PEDIDO"),
        }
    },

    created() {
        this.filter.situacao = "ATIVO";

        if(this.storange.default == undefined){
            this.filter = this.storange.filtros;
        }

        this.filter["compradorSelecionado"] = {codProdutor:""};
        this.pagina = this.storange.numero;
        this.primeiraLinha = this.storange.firstRow;
        this.qtdRegistroPorPagina = this.storange.qtdRegistro; 
        this.carregarDados();
    },

    methods: {

        editar(id) {                
            this.$router.push(`/pedido/editar/${ id }`);
        },

        filtrar(filtro){
            this.contratoFiltroAvancadoDialog = false;
          if (!!filtro) {this.carregarDados(filtro);}
        },

        carregarDados(filtros) {
            this.filter["page"] = this.pagina;
            this.filter["size"] = this.qtdRegistroPorPagina;
            this.filter["sort"] = this.ordenar;
            this.filter["nomeCliente"] = undefined;
            if(!!this.periodoInicioPedido) {
              this.filter.periodoInicioPedido = Formatacao.formatDateCustom(this.periodoInicioPedido, 'YYYY-MM-DD');
            } else {
              this.filter.periodoInicioPedido = undefined;
            }
            if(!!this.periodoFimPedido) {
              this.filter.periodoFimPedido = Formatacao.formatDateCustom(this.periodoFimPedido, 'YYYY-MM-DD');
            } else {
              this.filter.periodoFimPedido = undefined;
            }

            if(!!filtros){
              this.filter["contratoJuridico"] = filtros.contratoJuridico;
              this.filter["contratoCorretora"] = filtros.contratoCorretora;
              this.filter["idCorretora"] = filtros.corretoraId;
              this.filter["periodoInicio"] = filtros.periodoInicio;
              this.filter["periodoFim"] = filtros.periodoFim;
              this.filter["idProduto"] = filtros.idProduto;
            }


            if (!!this.filter.compradorSelecionado){
              this.filter["nomeCliente"] = this.filter.compradorSelecionado.nome;
            }

            GestaoFaturamentoPedidoService.buscarComPaginacao(this.filter)
                .then(({ data }) => {
                    if(data) {
                        this.data = data.content;
                        this.municipio = data.content.destino;
                        this.qtdRegistroPorPagina = data.pageable.pageSize;
                        this.totalRegistro = data.totalElements;
                    }
                })
                .catch(error => {
                    const toast = {
                        severity:'error',
                        summary: 'Falha ao buscar os pedidos',
                        detail:`Falha não mapeada.`, 
                        life: 7500
                    };

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });

            StorageService.setControlePaginaFilter("PEDIDO", this.pagina, this.primeiraLinha, this.qtdRegistroPorPagina, this.filter);               
        },

        pesquisarComprador(filtros) {
          ProdutorService.pesquisarProdutor(filtros)
              .then(({ data }) => {
                if(data) {
                  this.compradorConsulta = data;
                }
              })
              .catch(error => {
                this.compradorConsulta = {}

                const toast = {
                  severity: 'error',
                  summary: 'Falha ao carregar os compradores.',
                  detail: error,
                  life: 7500
                }

                if(!!error && !!error.response && !!error.response.data) {
                  const { message } = error.response.data;
                  toast["detail"] = message;
                  toast["severity"] = 'warn';
                }

                this.$toast.add(toast);
              });
        },

        selecionarComprador(codProdutor) {
          if(codProdutor == undefined || codProdutor == null || codProdutor.trim() == "") {
            this.compradorSelecionado = {};
            this.filter.compradorSelecionado = {};
            return;
          }

          ProdutorService.buscarPorCodProdutorInfoContrato(codProdutor)
              .then(({ data }) => {
                if(data) {
                  this.compradorSelecionado = data;
                  this.filter.compradorSelecionado = data;

                }
              })
              .catch(error => {
                this.compradorSelecionado = {};
                this.filter.compradorSelecionado = {};


                const toast = {
                  severity: 'error',
                  summary: `Falha ao buscar o comprador.`,
                  detail: error,
                  life: 7500
                }

                if(!!error && !!error.response && !!error.response.data) {
                  const { message } = error.response.data;
                  if(!!message) {
                    toast["detail"] = message.replace("produtor", "comprador");
                  }
                  toast["severity"] = 'warn';
                }

                this.$toast.add(toast);
              });
        },

        limparFiltro() {
            this.filter["nomeCliente"] = undefined
            this.filter["periodoInicioPedido"] = undefined;
            this.filter["periodoFimPedido"] = undefined;
            this.filter["periodoInicio"] = undefined;
            this.filter["periodoFim"] = undefined;
            this.filter["idContrato"] = undefined;
            this.filter["idPedido"] = undefined;
            this.filter["idCorretora"] = undefined;
            this.filter["compradorSelecionado"] = {codProdutor:""};
            this.filter["idProduto"] = undefined;
            this.filter["contratoJuridico"] = undefined;
            this.filter["contratoCorretora"] = undefined;

            this.periodoInicioPedido = null;
            this.periodoFimPedido = null;
            this.compradorConsulta = {};

            this.carregarDados();
        },

        confirmarAtivarOuInativar(embarque) {
            this.selecionado = embarque;
            this.ativarOuInativarDialog = true;
        },

        visualizarHistorico(embarque) {
            this.descricaoHistoricoDialog = "Contrato Interno: "+ embarque.contratoInternoId;
            this.historicoDialog = true;
            this.selecionado = embarque;
        },

        ocultarHistorico() {
            this.historicoDialog = false;
            this.selecionado = {};
        },

        ativarOuInativar() {
            if(this.selecionado.ativo) {
                GestaoFaturamentoPedidoService.inativar(this.selecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Inativado',
                            detail:`O pedido foi inativado com sucesso.`, 
                            life: 7500
                        });

                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.selecionado = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Falha ao inativar',
                            detail:`Falha não mapeada.`, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }
            else {
                GestaoFaturamentoPedidoService.ativar(this.selecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Ativada',
                            detail:`O pedido foi ativado com sucesso.`, 
                            life: 7500
                        });
                        
                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.selecionado = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Falha ao ativar',
                            detail:`Falha não mapeada.`, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }

            this.ativarOuInativarDialog = false;
        },

        pesquisarContrato(filtros) {
          ContratoCompraVendaService.buscarComPaginacao(filtros)
              .then(({ data }) => {
                if(data) {
                  this.contratoConsulta = data;
                }
              })
              .catch(error => {
                this.contratoConsulta = {}

                const toast = {
                  severity: 'error',
                  summary: 'Falha ao carregar os contratos.',
                  detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!",
                  life: 7500
                }

                if(!!error && !!error.response && !!error.response.data) {
                  const { message } = error.response.data;
                  toast["detail"] = message;
                }

                this.$toast.add(toast);
              });
        },

        selecionarContrato(codigo,registro,atualiza) {
          this.reload = atualiza;
          if(codigo == undefined || codigo == null) {
            this.contratoConsulta = {};
            return;
          }

          ContratoCompraVendaService.buscarPorContratoInterno(codigo)
              .then(({ data }) => {
                  this.contratoConsulta = data;
                  this.contratoConsulta["nome"] = data.comprador.nome;
                  if (this.reload){this.carregarDados();}
              })
              .catch(error => {
                this.contratoConsulta = {};

                const toast = {
                  severity: 'error',
                  summary: `Falha ao buscar contrato.`,
                  detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!",
                  life: 7500
                }

                if(!!error && !!error.response && !!error.response.data) {
                  const { message } = error.response.data;

                  if(!!message) {
                    toast["detail"] = message;
                  }
                }

                this.$toast.add(toast);
              });
        },

        formatDateCustom(date, format) {
            return Formatacao.formatDateCustom(date, format);
        },

        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        onPage(event) {
            this.pagina = event.page + 1;
            this.primeiraLinha = event.rows * event.page;
            this.qtdRegistroPorPagina = event.rows; 
            this.carregarDados();
        },

        sort(event) {
            const sortField = event.sortField;
            const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.ordenar = `${ sortField },${sortOrder}`;
            this.carregarDados();
        },

        toKg(vl,un){
          let fator = UnidadeMedidaService.buscarPorCodigo(un);
          return Formatacao.formatDecimal(vl * fator.fatorConversao,2,2);
        },

        getDescricaoStatus(codigo){
            if(codigo){
                const statusGestaoFaturamentoPedido = StatusGestaoFaturamentoPedidoService.buscarPorCodigo(codigo);

                if(statusGestaoFaturamentoPedido){
                    return statusGestaoFaturamentoPedido.descricao;
                }else{
                    return "";
                }
            }
        },

        getSiglaUnidadeMedida(codigo){
            if(codigo){
                const unidadeMedida = UnidadeMedidaService.buscarPorCodigo(codigo);

                if(unidadeMedida){
                    return unidadeMedida.sigla;
                }else{
                    return "";
                }
            }
        }
    }
}
</script>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }
</style>
